.nav-link {
  font-size: 21px;
  font-weight: 800;
  color: black;
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important
}

.navbar {
  padding: 0 1em !important;
 
}

.navbar-brand img {
  height: 60px;
}

.navbar-nav .nav-item {
  padding: 0 !important;
}

.navbar-nav .active > .nav-link {
  color: #d41a7a !important;
}

.navbar-nav .nav-link {
  color: #000 !important;
}
.navbar-expand-lg {
  background-color: white;
  width: 100%;
  padding: 1rem 5rem 1rem 5rem !important;
}
.nav-link {
  font-size: 16px !important;
  font-weight: 600 !important;
}
.btn-bookAppointment {
  border: 1px transparent;
  -webkit-border-radius: 30px;
  border-radius: 30px;
  color: #eeeeee;
  cursor: pointer;
  display: inline-block;
  font-family: Arial;
  font-size: 20px;
  padding: 8px 30px;
  text-align: center;
  text-decoration: none;
  margin-left: 20px;
  -webkit-animation: glowing 1300ms infinite;
  -moz-animation: glowing 1300ms infinite;
  -o-animation: glowing 1300ms infinite;
  animation: glowing 1300ms infinite;
  background-color: black;
  color: white;
  border: 2px solid black;
  border-radius: 30px;
}
@-webkit-keyframes glowing {
  0% {
    background-color: #00b74a;
    -webkit-box-shadow: 0 0 3px #00b74a;
  }
  50% {
    background-color: #00b74a;
    -webkit-box-shadow: 0 0 15px #00b74a;
  }
  100% {
    background-color: #00b74a;
    -webkit-box-shadow: 0 0 3px #00b74a;
  }
}
@keyframes glowing {
  0% {
    background-color: #00b74a;
    box-shadow: 0 0 3px #00b74a;
  }
  50% {
    background-color: #00b74a;
    box-shadow: 0 0 15px #00b74a;
  }
  100% {
    background-color: #00b74a;
    box-shadow: 0 0 3px #00b74a;
  }
}
.btn-bookAppointment:hover{
    transform: scale(1.25);
    transition-duration: .5s;
    background-color: #00b74a;
}

.navbar-toggler-icon {
  background-image: inherit;
 
}

.navbar-light .navbar-toggler {
  color: black;
}

/*Contact bar*/

.homeContact {
  background-color: black;
 
  padding: 1em 2em;
  margin: 0 !important;
}

.homeContactText h1 {
  text-align: center;
  color: white;
  vertical-align: middle;
  font-size: 30px; 
}
.btn-SpeakToUs{
color: #28a745;
    border-color: #28a745;}
.btn-SpeakToUs:hover {
  background-color: #00b74a;
  color: white;
}

.customfooter {
  background-color: black;
  min-height: 10vh;
  padding: 20px;
}

.customfooter p {
  font-size: 18px;
  font-weight: 400;
  color: white;
}

.showOnMobile {
  display: flex;
  float: right;
  text-align: center;
  padding-right: 0;
  padding-bottom: 5px;
}
.ssticky {
  position: fixed;
  top: 0;
  width: 100%;
  transition: all 0.5s ease;
  animation: smoothScroll 1s forwards;
  opacity: 100%;
  background-color: white;
}
.collpad{
  padding-left: 5px;
  
}
.collpad ul li{
  margin: 0 4px;
 
}
.showOnMobile a{  
  display: block;
  font-size: 21px;  
  font-weight: 700;
  float: left;
  color: rgb(255, 255, 255);
  
}
.showOnMobile1 a:hover{  
  transform: scale(1.5);
    transition-duration: 0.5s;
}
.hideInMobile {
  display: none;
}

.Appbuttonpagge{
 margin-top: 50px;
  justify-content:center;
  align-items:center;
  display:flex;
  transform: translateX(15px);

}
 .homeContactdata > input,
select {
  margin: 0 10px;
} 
/* Mobile adjustments */

@media (min-width: 860px) {
  .navbar-expand-lg {
    padding: 0rem 3rem 1rem 5rem;
  }
  .showOnMobile {
    display: flex;
    float: right;
  }
  .sticky{
    display: none;
  }
}

@media (max-width: 990px) {
  .homeContactdata {
    padding: 5px 3px;
    width: 70%;
  }
 
  .homeContact {
    padding: 1em 2em;
  }
  .showOnMobile {
    display: flex;
    float: right;
  }
}

@media (max-width: 900px) {
  
  .navbar {
    padding: 0 !important;

  }
  .btn-SpeakToUs{
    padding: 5px 3px;
    width: 50%;}
  .showOnMobile {
    display: flex;
  }
  .nav-container{
    padding: 10px !important;
  }
  .showOnMobile a{  
    display: block;
    font-size: 15px;  
    font-weight: 700;
    float: left;
    color: rgb(180, 70, 138);
    
  }
  
}
@media (max-width: 700px) {
  
  .showOnMobile {
    display: none;
  } 
  .hideInMobile {
    display: flex;
  }
  .sticky{
    display: none;
  }
  .hideInMobile a{  
    display: block;
    font-size: 15px;  
    font-weight: 700;
    float: left;
    color: rgb(180, 70, 138);
    
  }
  .hideInMobile a:hover{  
    transform: scale(1.5);
      transition-duration: 0.5s;
  }
}

.techcard {
  gap: 20px;
}
.topcontact
{
  float: right;
  text-align: center;
  padding-right: 0;
  padding-top: 5px;
}

#Nav{

display:table-row;

}
.header {
  padding: 80px;
  text-align: center;
  color: white;
  
  background-size: cover;
}
@keyframes smoothScroll {
  0% {
    transform: translateY(-142px);
  }
 
  100% {
    transform: translateY(0px);
  }
}
.main {
  -ms-flex: 70%;
  flex: 70%;
  background-color: white;
  padding: 20px;
}