.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.headerIcon {
  width: 30px;
  height: 30px !important;
}

.headerIconPhone {
  width: 24px;
  height: 24px !important;
}

.footerLinks {
  color: #ffffff;
  text-decoration: underline;
}

.footerLinks:hover {
  color: #d41a7a;
}

.why-chose-us {
  padding: 5% 3%;
}

.why-chose-us-video {
  padding: 2% 0;
  max-height: 500px;
  margin-top: 8%;
}

.HomePage-Image img {
  width: 100%;
}

.why-chose-us p {
  font-size: 18px;
  font-weight: 400;
  color: #000000;
  text-align: left;
}

.section-title {
	margin-bottom: 45px;
}

.section-title span {
	font-size: 15px;
	color: #888888;
	font-weight: 500;
	letter-spacing: 2px;
	text-transform: uppercase;
}

.section-title h2 {
	color: #111111;
	font-weight: 700;
	margin-top: 8px;
}

.chooseus {
	padding-bottom: 50px;
}

.chooseus__item {
	text-align: center;
	margin-bottom: 30px;
}

.chooseus__item h5 {
	font-size: 27px;
	color: #111111;
	font-weight: 600;
	margin-top: 26px;
	margin-bottom: 16px;
}

.chooseus__item p {
	margin-bottom: 0;
}

.why-chose-us h1 {
  padding-bottom: 3%;
  font-size: 30px;
  font-weight: 800;
  color: #d41a7a;
}

/* Mobile adjustments */

@media (min-width: 801px) {
}

@media (max-width: 800px) {
}
.containers {
  max-width: 1170px;
  width: 90%;
  margin: 50px auto;
  align-items: center;
  justify-content: center;
}

.container {
  width: 90%;
  max-width: 1000px;
  margin: 50px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: aqua; */
}
.testimonial-content p {
  font-size: 10px;
}

.left {
  width: 90%;
  height: 450px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 40px;
  border-left-color: coral;
}

.right {
  width: 85%;
  height: 200px;
  min-height: 200px;
  background-color: #ffffff;
  display: flex;
  align-items: left;
  justify-content: left;
  padding: 30px;
  border-radius: 25px;
  color: white;
  margin-bottom: -100%;
  margin-left: -65%;
}

.left img {
  width: 100%;
}

.right h1 {
  font-size: 14px;
  font-weight: lighter;
  color: #5f5f7e;
  font-weight: 600;
  text-align: left;
}

.right p {
  margin: 20px 0;
  color: #5f5f7e;
  font-weight: 400;
  font-size: 10px;
  text-align: left;
  border-left-color: grey;
}

.testimonial-card-row .col-sm-12 {
  padding-top: 5rem;
}

@media only screen and (max-width: 768px) {
  .container {
    flex-direction: column;
    width: 100%;
    margin: 0 20px;
  }
  .left {
    width: 100%;
    height: 400px;
  }
  .right {
    width: 90%;
    margin: 0;
    margin-top: -100px;
  }
  .portfolio-left {
    width: 100%;
    height: 400px;
    margin-left: -15%;
    position: relative;
  }
  .portfolio-right {
    width: 90%;
    height: 40%;
    margin: 0;
    margin-top: -195%;
  }
  .dummy-col {
    padding: 0;
  }
}

.bgBubble {
  background-position: left 0% top 12%;
}

.testimonial-card-row-bot {
  margin-bottom: 5%;
}

.why-chose-us-video iframe {
  /* width="549" ;
height="309"; */
  position: relative;
  height: 100%;
  width: 80%;
}
.why-chose-us-row {
  padding: 0 6%;
}
.portfolio-header h1,
.testimonial-header h1 {
  font-size: 30px;
  font-weight: 800;
  color: #d41a7a;
  padding: 40px 80px;
}

.tech-cards {
  padding: 2% 0%;
}
.tech-cards-col {
  padding: 2% 5%;
}

.tech-cards-col img {
  width: 60px;
}

.testimonialImg {
  width: 100%;
  margin: 0 1em;
  max-height: 600px;
}

.porfolio-content li a {
  color: #000;
}

.porfolio-content li a:hover {
  color: #d41a7a !important;
  text-decoration: none;
}

.content p,
h1,
.booking-content p,
h1 {
  font-size: 30px;
  text-align: left;
}
.content h2{
  text-align: left;
}
.content p {
  font-size: 20px;
  padding: 1em 0;
}
.content h1,
.booking-content h1 {
  color: #d41a7a;
  font-size: 42px;

  font-weight: 800;
}
.content img {
  width: 100%;
  padding: 2% 0%;
}
.content {
  padding: 4% 10%;
}
.booking-columns {
  padding: 3% 7%;
}
.booking-left form {
  border-radius: 10px;
  
  opacity: 80;
  padding: 3%;
  text-align: left;
  color: #6f7482;
  font-size: 16px;
}
.Details-submit {
  width: 100%;
  background-color: #ea3b96;
  border-color: #ea3b96;
}
.Details-submit:hover {
  width: 100%;
  background-color: black;
  border-color: black;
}
.pink-text {
  color: #d41a7a;
  font-weight: 600;
}
.booking-right p {
  font-size: 19px;
}

.icons {
  width: 60px;
}

#floating {
  position: fixed;
  font-weight: bold;
  right: -45px;
  top: 20%;
  vertical-align: top;
  transform: rotate(7deg);
  -ms-transform: rotate(90deg); /* IE 9 */
  -moz-transform: rotate(90deg); /* Firefox */
  -webkit-transform: rotate(90deg); /* Safari and Chrome */
  -o-transform: rotate(90deg); /* Opera */
  z-index: 999;
  background-color: #d41a7a;
  border: none;
  color: white;
  padding: 20px;
  border-radius: 10px;
  cursor: pointer;

  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

#floating:hover {
  background: #ea3b96;
  box-shadow: 0 0 50px #ea3b96;
}

.radio input {
  margin-right: 1em;
  width: 1em;
  height: 1em;
}

.modalContent {
  display: flex;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

.modalText {
  padding: 2rem;
}

.modalContent img {
  width: 50%;
  height: 100%;
  object-fit: cover;
}
.hideinipod
{
  display: inline;
}
.showinipod{
  display:none;
}
@media screen and (max-width: 768px) {
  .modalContent {
    flex-direction: column;
  }

  .modalContent img {
    width: 100%;
  }
  .hideinipod{
    display: none;
  }
  .showinipod{
    display: inline;
  }
}

.alert {
  position: fixed;
  bottom: 10px;
  left: 10px;
  z-index: 9999;
}
.payonline {
  flex-direction: column;
  background-color: #fff;
  padding-top: 1em;
  border-radius: 12px;
}

.payonline h1 {
  padding-bottom: 3%;
  font-size: 43px;
  font-weight: 800;
  color: #d41a7a;
}
.payonline button {
  background-color: #d41a7a;
  border-color: #d41a7a;
}

.paymentstatus h1 {
  color: green;
  font-size: 30px;
}
.spinner {
  width: 300px;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}

.spinner span {
  font-size: 2rem;
  animation: fade 1s linear 0s infinite;
  padding-right: 1rem;
}

.half-spinner {
  width: 50px;
  height: 50px;
  border: 3px solid #03fc4e;
  border-top: 3px solid transparent;
  border-radius: 50%;
  animation: spin 0.5s linear 0s infinite;
}

.completed {
  font-size: 2rem;
  color: #03fc4e;

  animation: bigger 1s linear;
}

@keyframes bigger {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(2);
  }
}

@keyframes spin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.carouselcared 
{    
    position: relative;
    margin: 10px auto 0;
    background-image: none;
}
*, *::before, *::after {
    box-sizing: border-box;
  }
  
.carouselcared .box
{
    position: relative;
    float: left;
    margin-top: 30px;
}
.image {
    position: relative;
    width: 400px;
    height: 300px;
}
.image__img {
  display: block;
  width: 100%;
}
@media (min-width: 1500px) {
  .image {
  position: relative;
  width: 400px;
  height: 300px;
}}
.image__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  color: #ffffff;
  font-family: 'Quicksand', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.25s;
}

.image__overlay--blur {
  backdrop-filter: blur(5px);
}



.image__overlay > * {
  transform: translateY(20px);
  transition: transform 0.25s;
}

.image__overlay:hover {
  opacity: 1;
  
}

.image__overlay:hover > * {
  transform: translateY(0);
}

.image__title {
  font-size: 2em;
  font-weight: bold;
}

.image__description {
  font-size: 1.25em;
  margin-top: 0.25em;
  color: #111111;
}
.image__description ul {
  font-size: 1.25em;
  margin-top: 0.25em;
  color: #111111;
}
.image__description li{
  font-size: .75em;
  margin-top: 0.25em;
  color: #ffffff;
}
.spad {
	padding-top: 1px;
	padding-bottom: 20px;
}
.testimonials {
	overflow: hidden;
	background: #ffffff;
	
}

.testimonials .section-title {
	margin-bottom: 15px;
}

.testimonial__item {
	background: #ffffff;
	-webkit-box-shadow: 0px 5px 20px rgba(0, 61, 85, 0.1);
	box-shadow: 0px 5px 20px rgba(0, 61, 85, 0.1);
	border-radius: 10px;
	position: relative;
	padding: 40px 40px 30px 40px;
}

.testimonial__item .rating {
	position: absolute;
	right: 40px;
	top: 52px;
}

.testimonial__item .rating i {
	font-size: 13px;
	color: #e4c870;
}

.testimonial__item p {
	font-size: 17px;
	line-height: 28px;
	margin-bottom: 0;
}

.testimonial__author {
	overflow: hidden;
	padding-bottom: 30px;
	border-bottom: 1px solid #e1e1e1;
	margin-bottom: 20px;
}

.testimonial__author__icon {
	height: 50px;
	width: 50px;
	background: #ffffff;
	font-size: 18px;
	line-height: 50px;
	text-align: center;
	color: #ffffff;
	border-radius: 50%;
	float: left;
	margin-right: 20px;
}

.testimonial__author__text {
	overflow: hidden;
	padding-top: 4px;
}

.testimonial__author__text h5 {
	color: #111111;
	font-weight: 600;
}

.testimonial__author__text span {
	font-size: 13px;
	color: #888888;
}

.testimonial__slider .col-lg-6 {
	max-width: 100%;
}

.testimonial__slider.owl-carousel .owl-stage-outer {
	padding-top: 30px;
	padding-bottom: 40px;
	overflow: visible;
}

.testimonial__slider .owl-dots {
	text-align: center;
}

.testimonial__slider .owl-dots button {
	height: 10px;
	width: 10px;
	background: #d2d2d2;
	border-radius: 50%;
	margin-right: 6px;
}

.testimonial__slider .owl-dots button.active {
	background: #9c9c9c;
}

.testimonial__slider .owl-dots button:last-child {
	margin-right: 0;
}