body{
  background-color:  #ffffff;
  
}
:root {
  --white: #fff;
  --orange: #f36c00;
}

* {
  box-sizing: border-box;
}

body
{
  font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button {
  cursor: pointer;
}

img {
  max-width: 100%;
}

.outer-container {
  width: 100%;
  margin: auto;
  display: flex;
  border: 1px solid rgb(187, 128, 128);
  border-radius: 15px;
  box-shadow: 1px 3px 6px rgba(0, 0, 0, 0.3);
  overflow: hidden;
}

.outer-container > div {
  flex: 1;
  padding: 20px;
}

.outer-container .carousel-wrapper {
  background: var(--orange);
  display: flex;
  flex-direction: column;
}

.outer-container .carousel-wrapper p,
.carousel-item {
  font-weight: 700;
  font-size: 20px;
  color: var(--white);
  line-height: 1.3;
}

.outer-container .content {
  background: var(--white);
  width: 1800px
}

.leading-text {
  display: flex;
  align-items: center;
  height: 100%;
  margin-right: 6px;
}

.carousel {
  height: 100%;
  display: flex;
  padding-top: .1rem;
  flex: 5;
  align-items: center;
  color: var(--white);
}

.carousel-button {
  display: flex;
  align-self: center;
  padding: 10px;
  max-width: 50px;
  border-radius: 30px;
  background: var(--orange);
  border: 2px solid var(--white);
}

.carousel-button svg {
  height: 26px;
  width: 26px;
}
.carousel-button path {
  fill: var(--white);
}

.slides {
  align-self: flex-start;
  flex: 1;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.blogcarousel-inner {
  position: relative;
  height: 1000px;
  width: 500px;
}

.blogcarousel-inner::before,
.carousel-inner::after {
  content: "";
  z-index: 1;
  position: absolute;
  width: 100%;
  height: 110px;
  pointer-events: none;
}

.blogcarousel-inner::before {
  top: 0;
  background: linear-gradient(
    0deg,
    hsla(0, 0%, 100%, 0) 0%,
    hsla(27, 100%, 48%, 1) 100%
  );
}

.blogcarousel-inner::after {
  bottom: 0;
  background: linear-gradient(
      0deg,
      hsla(27, 100%, 48%, 1) 0%,
      hsla(0, 0%, 100%, 0) 100%
    );
}

.blogcarousel-item {
  position: absolute;
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  opacity: 0;
  top: 112px;
  transition: transform 0.4s ease, opacity 0.4s ease;
}

.carousel-item.visible {
  opacity: 1;
}

.Blogbanner { 
  color: rgb(150, 34, 111);
  border:0px solid rgb(126, 46, 93);
  background-color:  #e7afcc; 
  height:100%;  text-align: left;
  width: 100%;
   
    display: flex;
    align-items: center;
    justify-content: center;
}

.col-xs-12 {
  width: 100%;
}
.col-sm-12 {
  width: 100%;
}
.col-md-12
{
  float: left;
  width: 100%;
}
.col-md-8
{
  width: 66.67%;
}
.col-md-4
{
  width: 33.333%;
}


.blogtending h1
{   
 
  font-size: 50px;
   font-weight: 500;
   line-height: normal;
   padding: 0 30px; 
}
.blogtending ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.blogtending ul li {
  float: left;
  line-height: 40px;
  margin: 10px;
}
.blogtending ul li a {
  padding: 0 10px;
  font-size: 22px;
  color: #fff;
  background-color: #00b6be;
  box-shadow: 0 0 4px rgb(0 0 0 / 40%);
  text-align: center;
  display: block;
}
.Blogfirst { 
  color:   rgb(150, 34, 111);
  border:0px solid rgb(126, 46, 93);
  background-color:  #dfdedd;
  height:100%;  text-align: left;
  width: 100%;
   
    display: flex;
    align-items: center;
    justify-content: center;
}.Blogsec { 
  color:   rgb(150, 34, 111);
  border:0px solid rgb(126, 46, 93);
  background-color:  #d8d8c8;
  height:100%;  text-align: left;
  width: 100%;
   
    display: flex;
    align-items: center;
    justify-content: center;
}
.containerb{
  width: 85%;  
}
.containerbc{
  width: 100%;  
}
.blogtending
{
  padding: 40px 0;
}
.blogcon
{
  padding: 40px 0;
}
.blogcon h1
{   
  font-size: 20px;
  color: #55578D;
  font-weight: 600;
  text-align: center;
  margin: 20px 0;
}



.Conterpadding {
  text-align: justify;
  font-family: sans-serif;; 
  padding: 10px ;
  
}




@media only screen and (max-width: 450px) {
  .blogtending h1
{   
   width: 100%;
   font-size: 36px;
    font-weight: 500;
    line-height: normal;
    padding: 0 30px; 
}
  
}
@media only screen and (max-width: 780px) {
  .blogtending h1
{   
   width: 100%;
   font-size: 40px;
    font-weight: 500;
    line-height: normal;
    padding: 0 30px; 
}
  
}

.HomePage-Image{

  padding: 20px;  
  width: 100%;
 background-attachment: fixed;
 
}
.contentb p,
h1{
  text-align: left;
}
.contentb p {
  font-size: 20px;
  padding: 1em 0;
}
.contentb h1{
  color: #0e0d0d;;
  font-size: 43px;

  font-weight: 800;
}
.contentb h3{
  color: #0e0d0d;;
  font-size: 30px;

  font-weight: 800;
}
.contentb h4{  
  font-size: 30px;
  font-weight: 500;
}
.contentb img {
  width: 800px;
  max-height:400px;
}
.contentb {
  padding: 4% 10%;
}
.Center-Headers 
{text-align: center;
  color: #0e0d0d;
  font-weight: 500;
  font-size: 30px;
font: optional;
}
